import Dashboard from "./../components/Dashboard";

import DetailsAdd from "../components/Details/Add";
import DetailsList from "../components/Details/List";

import UserVerificationList from "../components/UserVerification/List";
import UserVerificationDetails from "../components/UserVerification/Details";

import Add from "../components/VerificationForm/VerificationForm";

import SettingAdd from "../components/Settings/Add";
import UsersList from "../components/Users/List"
import UsersAdd from "../components/Users/Add"

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    isPublic: false,
    exact: true,
  },

  {
    path: "/details/list",
    name: "detailsList",
    component: DetailsList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/details/add",
    name: "detailsAdd",
    component: DetailsAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/details/:id/edit",
    name: "detailsEdit",
    component: DetailsAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/user_verification/list",
    name: "userVerificationList",
    component: UserVerificationList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/user_verification/:id/details",
    name: "userVerificationDetails",
    component: UserVerificationDetails,
    isPublic: false,
    exact: true,
  },
  {
    path: "/verification_form/add",
    name: "verificationForm",
    component: Add,
    isPublic: false,
    exact: true,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/users/list",
    name: "usersList",
    component: UsersList,
    isPublic: false,
    exact: true,
  },
  {
    path: "/users/add",
    name: "usersAdd",
    component: UsersAdd,
    isPublic: false,
    exact: true,
  },
  {
    path: "/users/:id/edit",
    name: "userEdit",
    component: UsersAdd,
    isPublic: false,
    exact: true,
  },
];

export default routes;
