import React, { useEffect } from "react";
import Select from "react-select";
import api from "@evenlogics/whf-api";
import "./VerificationForm.css";
import Swal from "sweetalert2";

const Add = () => {
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [user, setUser] = React.useState({
    id: "",
    fname: "",
    lname: "",
    mname: "",
    altfname1: "",
    altlname1: "",
    altfname2: "",
    altlname2: "",
    ssn: "",
    last4ssn: "",
    state: "",
    city: "",
    street: "",
    house_no: "",
    zip: "",
    keyword: "",
    g: "",
    email: "",
    bday: "",
    city2: "",
    state2: "",
    country: "",
    created_at: "",
  });

  const [gender, setGender] = React.useState(`${user.g}`);

  useEffect(() => {
    api
      .request("get", `/usersData?limit=10000000`)
      .then((res) => {
        let options = res?.data?.map((user) => {
          return {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          };
        });
        setOptions(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const onchangeSelect = (selected, clear) => {
    if (!clear) {
      Swal.showLoading();
      if (selected) {
        setSelected(selected);
        api
          .request("get", `/usersData/${selected.value}`)
          .then((res) => {
            let updatedUser = res?.data;
            setUser({
              id: updatedUser?.id,
              fname: updatedUser?.first_name,
              lname: updatedUser?.last_name,
              mname: updatedUser?.middle_name,
              altfname1: updatedUser?.alternate_first_name_1,
              altlname1: updatedUser?.alternate_last_name_1,
              altfname2: updatedUser?.alternate_first_name_2,
              altlname2: updatedUser?.alternate_last_name_2,
              ssn: updatedUser?.ssn,
              last4ssn: updatedUser?.last_4_ssn,
              state: updatedUser?.state,
              city: updatedUser?.city,
              street: updatedUser?.street,
              house_no: updatedUser?.house_no,
              zip: updatedUser?.zip,
              keyword: updatedUser?.keyword,
              g: updatedUser?.gender,
              email: updatedUser?.email,
              // bday: `${updatedUser?.birthday.split("-").reverse().join("-")}`,
              bday: updatedUser?.birthday,
              city2: `${updatedUser?.birth_place.split(", ")[0]}`,
              state2: `${updatedUser?.birth_place.split(", ")[1]}`,
              country: `${updatedUser?.birth_place.split(", ")[2]}`,
              created_at: updatedUser?.created_at,
            });
            setGender(`${updatedUser?.gender}`);
            Swal.close();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setUser({
        id: "",
        fname: "",
        lname: "",
        mname: "",
        altfname1: "",
        altlname1: "",
        altfname2: "",
        altlname2: "",
        ssn: "",
        last4ssn: "",
        state: "",
        city: "",
        street: "",
        house_no: "",
        zip: "",
        keyword: "",
        g: "",
        email: "",
        bday: "",
        city2: "",
        state2: "",
        country: "",
        created_at: "",
      });
      setGender("");
      setSelected([]);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }
  };

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const refreshSelect = () => {
    console.log("refreshing select");
    setTimeout(() => {
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }, 3000);
  };
  return (
    <div>
      <div className="containerr">
        <div className="heading">
          <div className="logos">
            <div className="logo-circle">
              <img className="logo sam" src="images/sam.png" alt="sam" />
            </div>
            <div className="logo-circle">
              <img className="logo oig" src="images/oig.png" alt="oig" />
            </div>
            <div className="logo-circle">
              <img
                className="logo vermont"
                src="images/vermont.png"
                alt="vermont"
              />
            </div>
          </div>
          <h4>Fill Data for User Verification</h4>
        </div>
        <div className="inner-container">
          <div className="c-box">
            <form action="" method="">
              <div
                style={{
                  display: "flex",
                  margin: "0px 20px 20px 40px",
                }}
              >
                <Select
                  value={selected}
                  onChange={(selected) => onchangeSelect(selected, false)}
                  options={options}
                  placeholder="Select User"
                  className="select"
                />

                <div className="clear-btn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ height: "38px", width: "120px" }}
                    onClick={() => {
                      onchangeSelect({}, true);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {[
                {
                  0: [
                    {
                      label: "First Name",
                      display_name: "First Name",
                      name: "fname",
                      value: user.fname ? `${user.fname}` : "",
                      image: "images/user.png",
                      required: true,
                    },
                    {
                      label: "Last Name",
                      display_name: "Last Name",
                      name: "lname",
                      value: user.lname ? `${user.lname}` : "",
                      image: "images/user.png",
                      required: true,
                    },
                  ],
                },
                {
                  1: [
                    {
                      label: "Middle Name",
                      display_name: "Mid Name",
                      name: "mname",
                      value: user.mname ? `${user.mname}` : "",
                      image: "images/user.png",
                      required: false,
                    },
                    {
                      label: "Alternate First Name 1",
                      display_name: "Alt F-Name 1",
                      name: "altfname1",
                      value: user.altfname1 ? `${user.altfname1}` : "",
                      image: "images/user.png",
                      required: false,
                    },
                  ],
                },
                {
                  2: [
                    {
                      label: "Alternate Last Name 1",
                      display_name: "Alt L-Name 1",
                      name: "altlname1",
                      value: user.altlname1 ? `${user.altlname1}` : "",
                      image: "images/user.png",
                      required: false,
                    },
                    {
                      label: "Alternate First Name 2",
                      display_name: "Alt F-Name 2",
                      name: "altfname2",
                      value: user.altfname2 ? `${user.altfname2}` : "",
                      image: "images/user.png",
                      required: false,
                    },
                  ],
                },
              ].map((item, index) => {
                return (
                  <div className="pair" key={index}>
                    {Object.keys(item).map((key) => {
                      return (
                        <>
                          {item[key].map((item, index) => {
                            return (
                              <div
                                className="form-group field-container"
                                key={index}
                              >
                                <label
                                  className="bold label"
                                  htmlFor={item.name}
                                >
                                  {item.display_name}
                                </label>
                                <div className="flex">
                                  <div className="img-wrapper">
                                    <img
                                      src={item.image}
                                      alt={item.name}
                                      className="icon"
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    name={item.name}
                                    value={item.value}
                                    onChange={onChange}
                                    placeholder={item.label}
                                    id={item.name}
                                    className="form-control input-field"
                                    required={item.required}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                );
              })}
              <div className="pair">
                <div className="form-group field-container">
                  <label className="bold label" htmlFor="altlname2">
                    Alt L-Name 2
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/user.png"
                        alt="altlname2"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="altlname2"
                      placeholder="Alternate Last Name 2"
                      value={user.altlname2 ? `${user.altlname2}` : ""}
                      id="altlname2"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label className="bold label" htmlFor="ssn">
                    SSN
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/geo-alt-fill.svg"
                        alt="ssn"
                      />
                    </div>
                    <input
                      type="number"
                      className="form-control input-field number"
                      min="0"
                      placeholder="SSN"
                      value={user.ssn ? `${user.ssn}` : ""}
                      name="ssn"
                      id="ssn"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pair">
                <div className="form-group field-container">
                  <label className="bold label" htmlFor="last4ssn">
                    Last 4 SSN
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/geo-alt-fill.svg"
                        alt="last4ssn"
                      />
                    </div>
                    <input
                      type="number"
                      className="form-control input-field number"
                      name="last4ssn"
                      placeholder="Last 4 SSN"
                      value={user.last4ssn ? `${user.last4ssn}` : ""}
                      id="last4ssn"
                      maxLength="4"
                      minLength="4"
                      min="0"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label className="bold label" htmlFor="state">
                    State
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/location.png"
                        alt="state"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="state"
                      value={user.state ? `${user.state}` : ""}
                      placeholder="State"
                      id="state"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pair">
                <div className="form-group field-container">
                  <label htmlFor="city" className="bold label">
                    City
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/location.png"
                        alt="city"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="city"
                      value={user.city ? `${user.city}` : ""}
                      placeholder="City"
                      id="city"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label className="bold label" htmlFor="street">
                    Street
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/road-with-broken-line.png"
                        alt="street"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="street"
                      value={user.street ? `${user.street}` : ""}
                      placeholder="Street"
                      id="street"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pair">
                <div className="form-group field-container">
                  <label htmlFor="house_no" className="bold label">
                    House No
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img className="icon" src="images/home.png" alt="house" />
                    </div>
                    <input
                      type="number"
                      className="form-control input-field number"
                      placeholder="House No"
                      name="house_no"
                      value={user.house_no ? `${user.house_no}` : ""}
                      min="0"
                      id="house_no"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label htmlFor="zip" className="bold label">
                    Zip
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/zip-code.png"
                        alt="zip"
                      />
                    </div>
                    <input
                      type="number"
                      className="form-control input-field number"
                      placeholder="Zip"
                      name="zip"
                      value={user.zip ? `${user.zip}` : ""}
                      min="0"
                      id="zip"
                      maxLength="5"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pair">
                <div className="form-group field-container">
                  <label htmlFor="keyword" className="bold label">
                    Keyword
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/keyword.png"
                        alt="keyword"
                      />
                    </div>
                    <input
                      required
                      type="text"
                      className="form-control input-field"
                      placeholder="Keyword"
                      value={user.keyword ? `${user.keyword}` : ""}
                      name="keyword"
                      id="keyword"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label htmlFor="email" className="bold label">
                    Email
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/email.png"
                        alt="email"
                      />
                    </div>
                    <input
                      type="email"
                      className="form-control input-field"
                      placeholder="Email"
                      value={user.email ? `${user.email}` : ""}
                      name="email"
                      id="email"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div
                className="pair"
                style={{
                  justifyContent: "flex-start",
                  margin: "0rem 0rem 2rem 3.2rem",
                }}
              >
                <div className="form-group bday">
                  <label className="bold b-bold" htmlFor="bday">
                    Birthday
                  </label>
                  <input
                    className="form-control date-size"
                    type="date"
                    value={user.bday ? `${user.bday}` : ""}
                    name="bday"
                    id="bday"
                    onChange={onChange}
                  />
                </div>
                {/* <div style={{ display: "none" }} /> */}
              </div>

              <label
                style={{ marginLeft: "52px" }}
                className="bold"
                htmlFor="birthplace"
              >
                Birthplace:
              </label>
              <div className="pair">
                <div className="form-group field-container">
                  <label htmlFor="country" className="bold label">
                    Country
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/germany.png"
                        alt="country"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      placeholder="Country"
                      name="country"
                      value={user.country ? `${user.country}` : ""}
                      id="country"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="form-group field-container">
                  <label htmlFor="state2" className="bold label">
                    State
                  </label>
                  <div className="flex">
                    <div className="img-wrapper">
                      <img
                        className="icon"
                        src="images/location.png"
                        alt="state2"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="state2"
                      value={user.state2 ? `${user.state2}` : ""}
                      placeholder="State"
                      id="state2"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pair">
                <div className="form-group field-container fieldWidth">
                  <label htmlFor="city2" className="bold label">
                    City
                  </label>
                  <div className="flex">
                    <div className="big-img-wrapper">
                      <img
                        className="icon"
                        src="images/location.png"
                        alt="city2"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control input-field"
                      name="city2"
                      placeholder="City"
                      value={user.city2 ? `${user.city2}` : ""}
                      id="city2"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="invisible"></div>
              </div>

              <div style={{ marginLeft: "56px" }} className="form-group">
                <label className="bold" htmlFor="gender">
                  Gender:
                </label>
                <br />
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginRight: "20px",
                    }}
                  >
                    <label htmlFor="male">Male</label>
                    <input
                      style={{ height: "15px", width: "15px" }}
                      type="radio"
                      id="male"
                      name="gender"
                      checked={gender === "1"}
                      onChange={() => setGender("1")}
                    />
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginRight: "20px",
                    }}
                  >
                    <label htmlFor="female">Female</label>
                    <input
                      style={{ height: "15px", width: "15px" }}
                      type="radio"
                      id="female"
                      name="gender"
                      checked={gender === "2"}
                      onChange={() => setGender("2")}
                    />
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      marginRight: "20px",
                    }}
                  >
                    <label htmlFor="other">Other</label>
                    <input
                      style={{ height: "15px", width: "15px" }}
                      type="radio"
                      id="other"
                      name="gender"
                      checked={gender === "3"}
                      onChange={() => setGender("3")}
                    />
                  </span>
                </div>
              </div>
              {/* below fields are hidden. */}
              <input
                type="text"
                name="created_at"
                value={user.created_at ? `${user.created_at}` : ""}
                readOnly
                id="created_at"
                style={{ display: "none" }}
              />
              <input
                type="text"
                name="user_id"
                value={user.id ? `${user.id}` : ""}
                readOnly
                id="user_id"
                style={{ display: "none" }}
              />

              <div onClick={() => refreshSelect()} className="verify"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;

// you can download the icons from flaticon.com
