import React, { Component } from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import { FormGenerator } from "@evenlogics/whf-form-generator";
import api from "@evenlogics/whf-api";

export default class Add extends Component {
  state = {
    fields: {
      email: "",
    },
    settingLoading: true,
  };

  componentDidMount() {
    api
      .request("get", "/settings")
      .then((res) => {
        this.setState(
          {
            fields: { email: res.email },
            settingLoading: false,
          },
          () => {}
        );
      })
      .catch(() => {});
  }
  render() {
    const passwordFields = {
      current_password: {
        type: "password",
        label: "Current Password",
        required: true,
        name: "current_password",
        col: 3,
      },
      password: {
        type: "password",
        label: "New Password",
        required: true,
        name: "password",
        col: 3,
      },
      password_confirmation: {
        type: "password",
        label: "Confirm Password",
        required: true,
        name: "password_confirmation",
        col: 3,
        oneOf: "password",
      },
    };
    const generalSettingFields = {
      email: {
        type: "email",
        label: "Email",
        required: true,
        name: "email",
        col: 3,
      },
    };
    const { fields, settingLoading } = this.state;

    return (
      <div>
        <Card className="animated fadeIn">
          <Header title="Settings" />
          <CardBody>
            {!settingLoading && (
              <div className="card p-3">
                <h5>General Settings</h5>

                <FormGenerator
                  initialValues={{ ...fields }}
                  targetEntity="settings"
                  fields={generalSettingFields}
                  name="general-settings"
                  redirect="settings"
                />
              </div>
            )}
            <div className="card p-3">
              <h5>Update Password</h5>

              <FormGenerator
                targetEntity="update-password"
                fields={passwordFields}
                name="update-password"
                redirect="settings"
              />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
