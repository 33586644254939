import React from "react";
import { Card } from "reactstrap";
import { FormGenerator } from "@evenlogics/whf-form-generator";

const DetailsAdd = (props) => {
  const { id } = props.match.params;
  // do something with id
  let fields = {
    name: {
      type: "text",
      label: "Client Name",
      required: true,
      name: "name",
      col: 12 + " col-lg-4 col-md-6 ",
      placeholder: "Enter Name",
      className: "form-control-lg",
    },
    email: {
      type: "email",
      label: "Client Email",
      required: true,
      name: "email",
      col: 12 + " col-lg-4 col-md-6 ",
      placeholder: "Enter Name",
      className: "form-control-lg",
    },
  };
  return (
    <Card className="animated fadeIn globalCard" style={{ padding: "1rem" }}>
      <h3> Client Details </h3>

      <FormGenerator
        targetEntity={`clients`}
        fields={fields}
        name="clients"
        targetId={id}
        redirect="details/list"
      />
    </Card>
  );
};

export default DetailsAdd;
