import React, { useEffect, useState } from "react";
import { Card as CCard } from "reactstrap/lib";
import CardBody from "reactstrap/lib/CardBody";
import { Row, Col, Card } from "react-bootstrap";
import api from "@evenlogics/whf-api";

const UserVerificationDetails = (props) => {
  const { id } = props.match.params;
  const [user, setUser] = useState({});

  useEffect(() => {
    api
      .request("get", `/usersData/${id}`)
      .then((res) => {
        let updatedUser = { data: res.data };
        setUser(updatedUser);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setUser({});
    };
  }, [id]);

  return (
    <div>
      {user.data ? (
        <CCard className="animated fadeIn">
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px 16px 0px",
            }}
          >
            <Card.Title>User Details</Card.Title>{" "}
          </span>
          <hr />
          <CardBody>
            <Card style={{ padding: "1rem" }}>
              <Row style={{ paddingTop: "0", marginTop: "0" }}>
                {[
                  {
                    0: {
                      cart_title: "First Name",
                      cart_text: user?.data?.first_name,
                    },
                    1: {
                      cart_title: "Last Name",
                      cart_text: user?.data?.last_name,
                    },
                  },
                  {
                    0: {
                      cart_title: "Alternate First Name 1",
                      cart_text: user?.data?.alternate_first_name_1
                        ? user?.data?.alternate_first_name_1
                        : "None",
                    },
                    1: {
                      cart_title: "Alternate Last Name 1",
                      cart_text: user?.data?.alternate_last_name_1
                        ? user?.data?.alternate_last_name_1
                        : "None",
                    },
                  },
                  {
                    0: {
                      cart_title: "Gender",
                      cart_text:
                        user?.data?.gender === 1
                          ? "Male"
                          : user?.data?.gender === 2
                          ? "Female"
                          : "Other",
                    },
                    1: {
                      cart_title: "Birthday",
                      cart_text: user?.data?.birthday,
                    },
                  },
                  {
                    0: {
                      cart_title: "SSN",
                      cart_text: user?.data?.ssn,
                    },
                    1: {
                      cart_title: "Last 4 SSN",
                      cart_text: user?.data?.last_4_ssn,
                    },
                  },
                ].map((item, index) => {
                  return (
                    <Col
                      style={{ padding: "0", margin: "0" }}
                      md="3"
                      key={index}
                    >
                      <Col>
                        <br />
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item[0].cart_title}
                        </Card.Title>
                        <Card.Text>{item[0].cart_text}</Card.Text>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item[1].cart_title}
                        </Card.Title>
                        <Card.Text>{item[1].cart_text}</Card.Text>
                      </Col>
                    </Col>
                  );
                })}
              </Row>
              <Row style={{ paddingTop: "0", marginTop: "0" }}>
                {[
                  {
                    0: {
                      cart_title: "Middle Name",
                      cart_text: user?.data?.middle_name,
                    },
                    1: {
                      cart_title: "City",
                      cart_text: user?.data?.city,
                    },
                  },
                  {
                    0: {
                      cart_title: "Alternate First Name 2",
                      cart_text: user?.data?.alternate_first_name_2
                        ? user?.data?.alternate_first_name_2
                        : "None",
                    },
                    1: {
                      cart_title: "Alternate Last Name 2",
                      cart_text: user?.data?.alternate_last_name_2
                        ? user?.data?.alternate_last_name_2
                        : "None",
                    },
                  },
                  {
                    0: {
                      cart_title: "Birthplace",
                      cart_text: user?.data?.birth_place,
                    },
                    1: {
                      cart_title: "State",
                      cart_text: user?.data?.state,
                    },
                  },
                  {
                    0: {
                      cart_title: "Street",
                      cart_text: user?.data?.street,
                    },
                    1: {
                      cart_title: "Zip",
                      cart_text: user?.data?.zip,
                    },
                  },
                ].map((item, index) => {
                  return (
                    <Col
                      style={{ padding: "0", margin: "0" }}
                      md="3"
                      key={index}
                    >
                      <Col>
                        <br />
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item[0].cart_title}
                        </Card.Title>
                        <Card.Text>{item[0].cart_text}</Card.Text>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item[1].cart_title}
                        </Card.Title>
                        <Card.Text>{item[1].cart_text}</Card.Text>
                      </Col>
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </CardBody>
        </CCard>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserVerificationDetails;
