import React from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
import { FormGenerator } from "@evenlogics/whf-form-generator";

const Add = (props) => {
    const { id } = props.match.params;
    console.log(id)
    const fields = {
        email: {
            type: "email",
            label: "Email",
            required: true,
            name: "email",
            col: 3,
        },
        username: {
            type: "text",
            label: "username",
            required: true,
            name: "username",
            col: 3,
        },
        ...(!id && {
            password: {
                type: "password",
                label: "New Password",
                required: true,
                name: "password",
                col: 3,
            },
            password_confirmation: {
                type: "password",
                label: "Confirm Password",
                required: true,
                name: "password_confirmation",
                col: 3,
                oneOf: "password",
            }
        })
    };



    return (
        <div>
            <Card className="animated fadeIn">
                <Header title="Users" />
                <CardBody>

                    <div className="card p-3">

                        <FormGenerator
                            targetId={id}
                            targetEntity="users"
                            fields={fields}
                            name="users"
                            redirect="users/list"
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );

}
export default Add