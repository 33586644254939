import React from "react";
import RemoteTable from "@evenlogics/whf-remote-table";

const UserVerificationList = () => {
  // const { id } = props.match.params;

  const defaultSorted = [{ dataField: "id", order: "desc" }];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "ssn",
      text: "SSN",
      sort: true,
    },
    {
      dataField: "vcic",
      text: "VCIC",
      sort: true,
      formatter: (cell, row) => {
        if (row.vcic === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.vcic}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },
    {
      dataField: "oig",
      text: "OIG",
      sort: true,
      formatter: (cell, row) => {
        if (row.oig === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.oig}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },
    {
      dataField: "ahs_child",
      text: "AHS Child",
      sort: true,
      formatter: (cell, row) => {
        if (row.ahs_child === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.ahs_child}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },
    {
      dataField: "ahs_adult",
      text: "AHS Adult",
      sort: true,
      formatter: (cell, row) => {
        if (row.ahs_adult === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.ahs_adult}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },

    {
      dataField: "sam",
      text: "SAM",
      sort: true,
      formatter: (cell, row) => {
        if (row.sam === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.sam}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },
    {
      dataField: "google",
      text: "Google",
      sort: true,
      formatter: (cell, row) => {
        if (row.google === null) {
          return <span className="badge badge-warning">Pending</span>;
        } else {
          return (
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.open(
                  `${row.google}`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            >
              File
            </button>
          );
        }
      },
    },
  ];

  return (
    <RemoteTable
      entity="usersData"
      customEntity="user_verification"
      columns={columns}
      sort={defaultSorted}
      hideEdit={true}
      hideDetail={false}
      disableDelete={true}
    />
  );
};

export default UserVerificationList;
