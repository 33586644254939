import React from "react";
import RemoteTable from "@evenlogics/whf-remote-table";

const DetailsList = (props) => {
  const { id } = props.match.params;

  const defaultSorted = [{ dataField: "id", order: "desc" }];
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
  ];

  return (
    <RemoteTable
      entity="clients"
      customEntity="details"
      columns={columns}
      sort={defaultSorted}
      targetId={id}
      hideEdit={false}
      hideDetail={true}
      disableDelete={false}
      addRoute={"/details/add"}
    />
  );
};

export default DetailsList;
