export default {
	// dashboard: {
	//   _tag: "CSidebarNavItem",
	//   name: "Dashboard",
	//   phrase: "dashboard",
	//   to: "/",
	//   order: 1,
	//   icon: "fas fa-tachometer-alt",
	//   badge: {
	//     color: "info",
	//     text: "NEW",
	//   },
	// },
	details: {
	  _tag: "CSidebarNavItem",
	  name: "Timesheet",
	  phrase: "Timesheet",
	  order: 2,
	  icon: "fas fa-calendar-alt",
	  to: "/details/list",
	  roles:['timesheet-user','admin'],
	  children: [
		{
		  _tag: "CSidebarNavItem",
		  name: "Client Details",
		  phrase: "Client Details",
		  to: "/details/list",
		  icon: "fas fa-user-circle",
		},
	  ],
	},
	user_verification: {
	  _tag: "CSidebarNavItem",
	  name: "User Verification",
	  phrase: "User Verification",
	  to: "/user_verification/list",
	  order: 3,
	  icon: "fas fa-user-check",
	  roles:['verification-user','verification-admin']
	},
	verification_form: {
	  _tag: "CSidebarNavItem",
	  name: "Verification Form",
	  phrase: "Verification Form",
	  to: "/verification_form/add",
	  order: 4,
	  icon: "fas fa-file-alt",
	  roles:['verification-user','verification-admin']
	},
	users:{
	  _tag: "CSidebarNavItem",
	  name: "User",
	  phrase: "Users",
	  to: "/users/list",
	  order: 5,
	  icon: "fas fa-file-alt",
	  roles:['verification-admin','admin']
	},
  
	settings: {
	  _tag: "CSidebarNavItem",
	  name: "settings",
	  phrase: "Settings",
	  to: "/settings",
	  order: 6,
	  icon: "fa fa-cog",
	  roles:['admin','verification-admin']
	},
  };
  