import React from "react";
import RemoteTable from "@evenlogics/whf-remote-table";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { Header } from "@evenlogics/whf-ra-components";
const List = () => {
    

    const defaultSorted = [{ dataField: "id", order: "desc" }];
    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },

        {
            dataField: "username",
            text: "Username",
            sort: true,
        },

    ];

    return (
        <Card className="animated fadeIn">
            <Header title="Users" />
            <CardBody>
                <RemoteTable
                    entity="users"
                    customEntity="users"
                    columns={columns}
                    sort={defaultSorted}
                    hideEdit={false}
                    hideDetail={true}
                    disableDelete={false}
                    addRoute={'/users/add'}

                />
            </CardBody>
        </Card>
    );
};

export default List;
